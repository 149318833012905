:root {
    --screen-height: 100vh;
}

.colors {
    position: sticky;
    top: 0;
    z-index: 1;
}

.outer {
    background-color: white;
    margin-top: 5%;
}

.nested {
    position: relative;
    z-index: 0;
}

.comp {
    height: var(--screen-height);
    width:100%;
}

/* Necessary for the svg file animations */ 

.cls-1a,.cls-3{
    fill:#cdc6aa;
}
.cls-2{
    fill:#e2dbbf;
}
.cls-3,.cls-4,.cls-5{
    stroke:#000;stroke-miterlimit:10;
}
.cls-3{
    stroke-width:3px;
}
.cls-4{
    stroke-width:6px;
}
.cls-5{
    fill:#4d4d4d;stroke-width:2px;
}

.cls-1key{
    fill:#7e7b6a;
}
.cls-2key{
    fill:#636051;
}
.cls-3key{
    fill:#cdc6aa;
}
.cls-4key{
    fill:#bcb6a0;
}
.cls-5key{
    fill:#7d7a67;
}

/* Back to user created tags */ 
/*     Notice z-index to create layering*/

.nested {
    padding-top: 3%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 8%;
    position: relative;
    margin-top: -100vh;
    z-index: 0;
    font-family: 'Comic Sans', monospace;
    font-size: large;
}
.nested h1 {
    font-size: 4em;
}

.nested h2 {
    font-size: 3.5em;
}

.PackPal {
    color:orange;
}

.nested h3 {
    font-size: 3em;
}

.Datify {
    color: #0b304d;
}

.ind1 {
    padding-left: 6%;
}

.seq {
    color: #4d4d4d;
}

.prims {
    color: #858585;
}

.interpret {
    color: #004c86;
}

/* If Mobile display, schrink sizes and change bounds */
@media (max-width: 575.98px) {
    .nested {
        font-family: 'Comic Sans', monospace;
        padding-top: 9%;
        padding-left: 7%;
        padding-right: 7%;
        padding-bottom: 28%;
        position: relative;
        font-size: small;
        margin-top: -100vh;
        z-index: 0;
    }

    .nested h1 {
        font-size: 6.5vw;
    }
    
    .nested h2 {
        font-size: 4.5vw;
    }

    .nested h3 {
        font-size: 4vw;
    }
}