.grid .row p {
  color: #f2f6e9;
}

.features {
  margin: 4em auto;
  padding: 1em;
  position: relative;
}

.feature-title {
  color: #f2f6e9;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}
.features img {
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 16px;
}

.features .form-control,
.features input {
    border-radius: 0;
}
.features .btn {
  background-color: #0b304d;
  border: 1px solid #0b304d;
  color: #f2f6e9;
  margin-top: 20px;
}
.features .btn:hover {
  background-color: #333;
  border: 1px solid #333;
}

.nav-link{
  text-align: center;
  font-family: inherit;
  font-size: larger;
  color: #f2f6e9;
}