.navbar {
    background:#0b304d;
  }
.nav-link,
.navbar-brand {
  color: #fff;
  cursor: pointer;
}
.nav-link {
  margin-right: 1em !important;
  color: #F5F5F5;
}
.nav-link:hover {
  color: #F5F5F5;
}
.navbar-collapse {
  justify-content: flex-end;
}

.nav-item:hover {
  text-decoration: underline;
}

.nav-link a {
  color: white;
}

.navbar-brand a {
  color: white;
}

.navImg img {
  width: auto;
  height: 30px;
}