/* Adjusted by javascript, be careful changing, these values are close, but not perfect*/
:root {
  --aspect-ratio: -45.8deg;
  --set-height: 660px;
}

.header {
  /*background-image: url('/images/header-background.jpg');*/
  /*background-image: linear-gradient(166deg, #0b304d 70%, #F5F5F5 calc(30% + 2px));*/
  background: #4b4b4b;
  background-size: cover;
  background-position: center;
  position: relative;
  height: var(--set-height);
}


header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0em;
  left: 0;
  width: 100%;
  background: #0b304d;
  /*transform: skew(-63.8deg);*/
  transform: skew(var(--aspect-ratio));
  transform-origin:top;
}

.heads {
  mix-blend-mode: luminosity; /* normal, multiple, luminosity */
  font-family: monospace;
}

.heads h1 {
  font-size: 7 vh;
}

@media (max-width: 575.98px) {
  .heads h1 {
    font-size: 6vh;
  }
  .heads p {
    font-size: 3vh;
  }
}
/*
.overlay {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}
*/