.accord .accordion{
    margin-top: 2%;
    padding-left: 5%;
    background-color: #4b4b4b;
}
.accord .card-header {
    background-color: #4b4b4b;
    border-bottom: 0px;
    border-top: 0px;
}

.accord .btn {
    font-size: 4.5vw;
    text-align: center;
    font-family: monospace;
    white-space: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accord .card-body {
    background-color: #4b4b4b;
    color: white;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.8vw;
}

.accord .btn, .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    color: #0b304d;
}

.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 575.98px) {
    .accord .btn {
        font-size: 7.5vw;
        text-align: center;
        white-space: normal;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .accord .card-body {
        background-color: #4b4b4b;
        color: white;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 5vw;
    }
    
  }