.typeHead h1 {
    font-size: 11vw;
    text-align: center;
    color: #0b304d;
    padding-bottom: 3%;
}

.typein.lists h1 {
  font: 400 40px/1.5 Helvetica, Verdana, sans-serif;
  margin: 0;
}

.typein.lists h2 {
  font: 400 40px/1.5 Helvetica, Verdana, sans-serif;
  margin: 0;
  padding-left: 5%;
  font-size: larger;
  color: rgba(255, 255, 255, 0.7);
}


.typein.lists ul {
  list-style-type: none;
  margin: 0;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0%;
  padding-top: 0%;
}

@media (max-width: 575.98px) {
  .typeHead h1 {
    font-size: 16vw;
    text-align: center;
    color: #0b304d;
    padding-bottom: 3%;
  }
  
  .typein.lists h1 {
    font: Helvetica, Verdana, sans-serif;
    margin: 0;
    font-size: 8vw;
  }
  
  .typein.lists h2 {
    font: Helvetica, Verdana, sans-serif;
    margin: 0;
    padding-left: 5%;
    font-size: 6vw;
    color: #fff;
    list-style-type: disc;
  }
  
}

.typein.lists li {
  font: 200 20px/1.5 Helvetica, Verdana, sans-serif;
  transition: all .2s ease-in-out;
  color: white;
  /*border-bottom: 1px solid #ccc;*/
}

.typein.lists li:last-child {
  border: none;
}

.typein.lists li a {
  text-decoration: none;
  color: #000;
  
  /*-webkit-transition: font-size 0.3s ease, background-color 0.3s ease;
  -moz-transition: font-size 0.3s ease, background-color 0.3s ease;
  -o-transition: font-size 0.3s ease, background-color 0.3s ease;
  -ms-transition: font-size 0.3s ease, background-color 0.3s ease;
  transition: font-size 0.3s ease, background-color 0.3s ease;*/
  display: block;
  width: 200px;
}

.typein.lists li:hover {
  font-size: 30px;
  background: #4b4b4b;
}