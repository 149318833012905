.edu {
    margin-top: 5%;
    margin-bottom: 5%;
}

.edu .school {
  width: 50%;
  height: auto;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 575.98px) {
  .edu {
      margin-top: 15%;
      margin-bottom: 15%;
  }
  .edu .school {
    width: 90%
  }
}