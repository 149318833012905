/* Overarching Bootstrap css over Splash, Nav, and Grid js originating from Bootstrap tutorial */
body {
  padding: 0;
  margin: 0;
  background: #4b4b4b;
}
.description {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -55%);
  text-align: center;
}
.description h1 {
  color: #f2f6e9;
}
.description p {
  color: #f2f6e9;
  font-size: 1.3rem;
  line-height: 1.5;
}
.description button {
  border:2px solid #f2f6e9;
  background:rgba(255,255,255,0);
  border-radius: 0;
  color:#f2f6e9;
  /*color: rgba(255,255,255,0);*/
}
.description button:hover {
  border:2px solid #f2f6e9;
  background:#f2f6e9;
  color:#000;
}
  
@media (max-width: 575.98px) {
  .description {
    left: 0;
    padding: 0 15px;
    position: absolute;
    top: 10%;
    transform: none;
    text-align: center;
  }
  
  .description h1 {
    font-size: 2em;
  }
  
  .description p {
    font-size: 1.2rem;
    color: #f2f6e9;
  }
  
  .features {
    margin: 0;
  }
}