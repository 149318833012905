:root {
  --wait: 3.5s;
}

.cls-1 {
  stroke: #0b304d;
  stroke-miterlimit: 10;
  stroke-width: 7px;
}

.cls-1.CWRU {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.1px;
}

.demos {
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0%;
  box-sizing: border-box;
}

.logo {
  position: relative;
  left: 50%;
  top: 20%;
  transform: translate(-50%, 0%);
  width: 60%;
  height: auto;
  fill: transparent;
}

.logo-anim {
    animation: fill 0.5s ease forwards var(--wait);
}

.logo-anim.CWRU {
  animation: fill2 0.5s ease forwards var(--wait);
}

@keyframes line-anim{
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill{
  from {
    fill: transparent;
  }
  to {
    fill: #0b304d;
  }
}

@keyframes fill2{
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
}

/* Matches for any screens smaller than that size (smartphones)*/
@media (max-width: 575.98px) {
    .logo {
        width: 100%;
    }
}